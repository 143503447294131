/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-diff-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M8.5 4.5V6H10a.5.5 0 010 1H8.5v1.5a.5.5 0 01-1 0V7H6a.5.5 0 010-1h1.5V4.5a.5.5 0 011 0M6 10h4a.5.5 0 010 1H6a.5.5 0 010-1"/>',
    },
});
